import { Box } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { transformSignUpData } from "src/adapter/signUpData";
import {
  AutoComplete,
  Button,
  Input,
  PhoneNumber,
} from "src/components/common/FormComponents";
import { SuccessModal } from "src/components/common/modal";
import OnboardingWrapper from "src/components/common/OnboardingWrapper";
import errorMessage from "src/constants/errorMessage";
import { path } from "src/constants/path";
import {
  updateImplementingOfficerDetails,
  updateStepper,
} from "src/redux/actions";
import { resetFormAction } from "src/redux/createActions/resetForms";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import { getStates } from "src/utils/api/roles";
import { signUp } from "src/utils/api/signUp";
import { optionTypeFormatter } from "src/utils/helper";
import regex from "src/utils/regex";
import defaultStyles, { StylesClasses } from "./styles";

export type implementingOfficerDetailsFormData = {
  officerDetails: {
    fullName: string;
    email: string;
    designation: string;
    district: string;
    division: string;
    phone: {
      countryCode: string;
      number: string;
    };
    mobilePhone: {
      countryCode: string;
      number: string;
    };
  }[];
};

const ImplementingOfficerDetails = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [response, setResponse] = useState({});

  const [stateOption, setStateOptions] = useState([]);

  const [openModal, setOpenModal] = useState({
    open: false,
    heading: "",
    subheading: "",
    description: "",
    btnText: "",
    type: "",
  });

  const { formData } = useAppSelector(
    (state) => state.root.implementingOfficerDetails
  );

  const { userType, agencyDetails, bankDetails, nodalOfficerDetails } =
    useAppSelector((rootState) => rootState.root);

  const { data } = useQuery(["getStatesType"], () => getStates(), {
    onSuccess: (data) => {
      setStateOptions(data.data);
    },
    refetchOnMount: true,
  });

  const stateOptions = optionTypeFormatter(stateOption);

  const adminSignUp = useMutation("signUp", (data: any) => signUp(data), {
    onSuccess: (res: AxiosResponse) => {
      setResponse({ "User name": res.data.userName });
      setOpenModal({
        open: true,
        heading: "",
        subheading: "Your account has been created!",
        description:
          "Login details will be sent to your registered Email and Phone Number of Nodal and Implementing officers",
        btnText: "Login",
        type: "success",
      });
      dispatch(resetFormAction({}));
    },

    onError: (err: AxiosError) => {
      setResponse({});
      setOpenModal({
        open: true,
        heading: "Unsuccessful",
        subheading: "",
        description: err.response.data.message || "Something went wrong",
        btnText: "",
        type: "unSuccess",
      });
    },
  });

  const defaultValues =
    formData?.officerDetails?.length > 0
      ? formData
      : {
          officerDetails: [
            {
              fullName: "",
              designation: "",
              district: "",
              division: "",
              phone: {
                countryCode: "+91",
                number: "",
              },
              mobilePhone: {
                countryCode: "+91",
                number: "",
              },
              email: "",
            },
          ],
        };

  const { control, formState, handleSubmit } =
    useForm<implementingOfficerDetailsFormData>({
      mode: "onTouched",
      defaultValues: {
        ...defaultValues,
      },
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "officerDetails",
  });

  const { errors, isValid } = formState;

  const handleFormSubmit = (data: implementingOfficerDetailsFormData) => {
    dispatch(updateImplementingOfficerDetails({ ...data }));
    dispatch(updateStepper({ id: 5 }));
    const updatedFormData = transformSignUpData({
      userType,
      agencyDetails,
      bankDetails,
      nodalOfficerDetails,
      implementingOfficerDetails: { formData: data },
    });
    adminSignUp.mutate(updatedFormData);
  };

  const handleResponseModal = () => {
    if (openModal.type === "success") {
      navigate(path.login, { state: { userId: response["User name"] } });
    } else {
      handleModalClose();
    }
  };

  const handleModalClose = () => {
    setOpenModal({ ...openModal, open: false });
  };

  const district = useMemo(() => {
    const d = agencyDetails?.formData?.state;
    const dist = stateOption.find((i) => i.id === d);
    return dist?.districts.length ? optionTypeFormatter(dist?.districts) : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyDetails?.formData?.state, stateOption]);

  return (
    <Box component={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
      <OnboardingWrapper
        heading="Implementing Officer Details"
        previousRoute={path.onboarding}
        nextBtnLabel="Finish"
        isValid={isValid}
      >
        {fields?.map((field, index) => (
          <Box {...styles("wrapper")} key={field.id}>
            <Box {...styles("input")}>
              <Input
                name={`officerDetails.${index}.fullName`}
                label="Name"
                placeholder="Enter your full name"
                control={control}
                rules={{ required: errorMessage.required }}
                errors={errors}
                height="52px"
              />
            </Box>
            <Box {...styles("input")}>
              <Input
                label="Designation"
                placeholder="Enter Designation"
                name={`officerDetails.${index}.designation`}
                control={control}
                rules={{ required: errorMessage.required }}
                errors={errors}
                height="52px"
              />
            </Box>
            <Box {...styles("input")}>
              <AutoComplete
                options={district.length ? district : []}
                rules={{ required: errorMessage.required }}
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                }}
                name={`officerDetails.${index}.district`}
                control={control}
                placeholder="District"
                label="District"
              />
            </Box>
            <Box {...styles("input")}>
              <Input
                name={`officerDetails.${index}.division`}
                label="Division"
                placeholder="Enter Division"
                control={control}
                rules={{ required: errorMessage.required }}
                errors={errors}
                height="52px"
              />
            </Box>
            <Box {...styles(["input", "withHelperText"])}>
              <PhoneNumber
                control={control}
                errors={errors}
                name={`officerDetails.${index}.phone`}
                label="Contact Number (Landline)"
                isRequired={false}
              />
            </Box>
            <Box {...styles(["input", "withHelperText"])}>
              <PhoneNumber
                control={control}
                errors={errors}
                name={`officerDetails.${index}.mobilePhone`}
                label="Contact Number (Mobile)"
              />
            </Box>
            <Box {...styles(["input", "withHelperText"])}>
              <Input
                name={`officerDetails.${index}.email`}
                label="Email"
                placeholder="Enter your email"
                control={control}
                rules={{
                  required: errorMessage.required,
                  pattern: {
                    value: regex.email,
                    message: errorMessage.invalidEmail,
                  },
                }}
                errors={errors}
                height="52px"
              />
            </Box>
            <Box
              {...styles(["inputContainer", "withHelperText"])}
              sx={{
                color: "primary.main",
                mt: "8px",
                textAlign: "right",
                width: "94%",
                cursor: "pointer",
              }}
              onClick={() => remove(index)}
            >
              {"Remove"}
            </Box>
          </Box>
        ))}
        <Box>
          <Button
            text="add New"
            variant="text"
            customStyles={{
              text: {
                fontSize: "16px",
              },
            }}
            onClick={() =>
              append({
                fullName: "",
                designation: "",
                district: "",
                division: "",
                phone: {
                  countryCode: "+91",
                  number: "",
                },
                mobilePhone: {
                  countryCode: "+91",
                  number: "",
                },
                email: "",
              })
            }
          />
        </Box>
      </OnboardingWrapper>
      <SuccessModal
        open={openModal.open}
        heading={openModal.heading}
        subheading={openModal.subheading}
        description={openModal.description}
        btnText={openModal.btnText}
        type={openModal.type}
        onClick={() => handleResponseModal()}
        handleModalClose={() => handleModalClose()}
      >
        {!!response && (
          <>
            {Object.keys(response).map((item) => (
              <Box {...styles("description")}>
                {item}: {response[item]}
              </Box>
            ))}
          </>
        )}
      </SuccessModal>
    </Box>
  );
};

export default ImplementingOfficerDetails;
