import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import DataGrid from "src/components/common/DataGrid";
import { AutoComplete, Input } from "src/components/common/FormComponents";
import Checkbox from "src/components/common/FormComponents/Checkbox";
import MapComponent from "src/components/common/KML";
import { SuccessModal } from "src/components/common/modal";
import errorMessage from "src/constants/errorMessage";
import { formHeaders } from "src/constants/sampleData";
import { setClose } from "src/redux/slices/newProjectSetup";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import {
  getProjectOptions,
  getProjectOptionsDetails,
  registerTreePlantation,
} from "src/utils/api/createProjectFlow";
import { optionTypeFormatter } from "src/utils/helper";
import FormWrapper from "../FormWrapper";
import defaultStyles, { StylesClasses } from "../NewForms/styles";
import { treePlantationTypes } from "../newTypes";

const formHeads = formHeaders["PlantationBlock"];

const PlantationBlock = ({ refetch }) => {
  const stateOption = useAppSelector((state) => state?.root.state.allStates);
  const styles = getStyles<StylesClasses>(defaultStyles);
  const [gridAreas, setGridAreas] = useState([]);
  const [landArea, setLandArea] = useState("");
  const [projectId, setProjectId] = useState("");

  const [tableRows, setTableRows] = useState([]);

  const [openModal, setOpenModal] = useState({
    open: false,
    heading: "",
    subheading: "",
    description: "",
    btnText: "",
    type: "",
  });

  const tableColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 200,
      sortable: false,
      hide: true,
    },
    {
      field: "registrationNo",
      headerName: "Registration No",
      width: 200,
      sortable: false,
    },
    {
      field: "areaUnit",
      headerName: "Total Area",
      width: 200,
      sortable: false,
    },
    {
      field: "availableArea",
      headerName: "Available Area",
      width: 200,
      sortable: false,
    },
    {
      field: "state",
      headerName: "State",
      width: 200,
      sortable: false,
    },
    {
      field: "district",
      headerName: "District",
      width: 200,
      sortable: false,
    },
  ];

  const dispatch = useAppDispatch();

  const { control, getValues, setValue, formState, watch, trigger } =
    useForm<treePlantationTypes>({
      mode: "onTouched",
    });

  const { refetch: fetchProject } = useQuery(
    "projectOptions",
    () =>
      getProjectOptions({
        projectType: "LAND_LEASED",
        selectedState: watch("stateName"),
        // selectedDistrict: watch("districtName"),
      }),
    {
      refetchOnMount: true,
      onSuccess: (data) => {
        setTableRows(
          data?.data?.rows.map((row) => ({
            data: row,
            id: row.id,
            registrationNo: row.registrationNo,
            areaUnit: `${(+row.landArea).toFixed(2)} ${row.areaUnit}`,
            availableArea: `${(+row.availableArea).toFixed(2)} ${row.areaUnit}`,
            state: row.address.stateDetail.name,
            district: row.address.districtDetail.name,
          }))
        );
      },
      enabled: false,
    }
  );

  const { data: projectOptionsDetails, refetch: fetchProjectDetails } =
    useQuery(
      ["projectOptionsDetails", projectId],
      () =>
        getProjectOptionsDetails({
          ID: projectId,
        }),
      {
        refetchOnMount: true,
        onSuccess: (res) => {
          setLandArea(
            `${
              (res?.data?.landSpecification?.totalArea &&
                res?.data?.landSpecification?.occupiedArea &&
                (
                  +res?.data?.landSpecification?.totalArea -
                  +res?.data?.landSpecification?.occupiedArea
                ).toFixed(2)) ??
              "-"
            }`
          );
        },
        enabled: !!projectId,
      }
    );

  const { isLoading: submitLoading, mutate } = useMutation(
    "tree-plantation-form",
    (data: {
      projectId: string;
      grids: {
        id: number;
        name: string;
      }[];
      totalArea: number;
    }) => registerTreePlantation(data),
    {
      onSuccess: () => {
        setOpenModal({
          open: true,
          heading: "Congratulations!",
          subheading: "Eco-Restoration Blocks Selected Successfully!",
          description: "",
          btnText: "Done",
          type: "success",
        });
        refetch();
      },
    }
  );

  const stateOptions = optionTypeFormatter(stateOption);

  const totalSelectedLand = () => {
    const selectedLandArea = getValues("selectLandArea");
    setValue("totalArea", selectedLandArea ? "" : landArea);
    console.log(getValues("totalArea"), "=======================");
  };

  const { isValid, errors } = formState;

  useEffect(() => {
    if (!!watch("stateName")) {
      fetchProject();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("stateName")]);

  useEffect(() => {
    if (!!watch("projectName")) {
      fetchProjectDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("projectName")]);

  const area = getValues("totalArea");

  const showButton = +landArea > 0;

  return (
    <FormWrapper
      isValid={isValid}
      onNext={() => {
        mutate({
          projectId: projectId,
          grids: gridAreas,
          totalArea: Number(getValues("totalArea")),
        });
      }}
      onBack={() => {}}
      subHeading={formHeads.details}
      currentStepNumber={1}
      showBackBtn={false}
      showButton={showButton}
      btnText="Submit"
      showStepper={false}
      isLoading={submitLoading}
    >
      <Box {...styles("formTitle")}>{formHeads.title}</Box>

      <Box {...styles("inputContainer")}>
        <Box {...styles("input")}>
          <AutoComplete
            options={stateOptions}
            onOptionSelect={(e) => {
              if (stateOption?.length && e) {
                setValue("districtName", "");
              }
            }}
            customStyles={{
              label: defaultStyles.label,
              wrapper: defaultStyles.selectWrapper,
            }}
            name="stateName"
            rules={{ required: errorMessage.required }}
            control={control}
            placeholder="State"
            label="State"
          />
        </Box>
      </Box>
      {!!tableRows.length && (
        <>
          <Box
            sx={{
              fontSize: "18px",
              fontWeight: "500",
              mt: "30px",
            }}
          >
            Please select area for plantation
          </Box>
          <Box
            sx={{
              ".MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-row:hover": {
                cursor: "pointer",
              },
              mt: "20px",
            }}
          >
            <DataGrid
              rows={tableRows || []}
              columns={tableColumns}
              rowCount={tableRows.length}
              rowsPerPageOptions={[20]}
              paginationMode="client"
              hidePagination={true}
              pageSize={50}
              onCellClick={(data) => setProjectId(data.row?.id)}
              getRowId={(row) => `${row.id}-${row.userName}`}
            />
          </Box>
        </>
      )}
      {!!tableRows.length && (
        <Box sx={{ mt: "40px" }}>
          <MapComponent
            kmlUrl={
              projectOptionsDetails?.data?.documentDetail[0].documentUrl.fileUrl
            }
          />
          <Box {...styles("inputContainer")}>
            <Box {...styles("input")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                }}
                readOnly={true}
                name="landArea"
                value={landArea}
                control={control}
                placeholder={`Available Land in the Plantation Block`}
                type="text"
                label={`Available Land in the Plantation Block`}
              />
            </Box>
            {+landArea > 0 && (
              <Box {...styles("checkbox")}>
                <Checkbox
                  name="selectLandArea"
                  control={control}
                  label={"Select Total Available Land for Plantation"}
                  isManual
                  handleChange={(e) => {
                    totalSelectedLand();
                    // trigger();
                  }}
                />
              </Box>
            )}

            {!getValues("selectLandArea") && (
              <Box {...styles("input")}>
                <Input
                  customStyles={{
                    label: defaultStyles.label,
                    wrapper: defaultStyles.selectWrapper,
                  }}
                  name="totalArea"
                  rules={{
                    required: {
                      value: !watch("selectLandArea"),
                      message: errorMessage.required,
                    },
                    min: {
                      value: 5,
                      message: "Area should be greater than 5 hectare",
                    },
                    max: {
                      value: landArea,
                      message: "Area should be greater than available Area",
                    },
                  }}
                  control={control}
                  errors={errors}
                  placeholder="Enter Area Manually for Plantation activities"
                  label="Enter Area Manually for Plantation activities"
                  type="number"
                />
                <Box
                  sx={{
                    color: "#272727",
                    fontSize: "11px",
                    fontWeight: "400",
                    position: "relative",
                    bottom: !!errors.totalArea ? "-4px" : "10px",
                  }}
                >
                  (Not less than 5 hectares)
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}

      <SuccessModal
        open={openModal.open}
        heading={openModal.heading}
        subheading={openModal.subheading}
        description={openModal.description}
        btnText={openModal.btnText}
        type={openModal.type}
        onClick={() => {
          dispatch(setClose());
        }}
        handleModalClose={() => {
          dispatch(setClose());
        }}
      />
    </FormWrapper>
  );
};

export default PlantationBlock;
