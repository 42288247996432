import {
  FormControlLabel,
  FormControlProps,
  Checkbox as MuiCheckbox,
} from "@mui/material";
import {
  Control,
  Controller,
  ControllerProps,
  FieldValues,
} from "react-hook-form";
import { CustomStyles, getStyles } from "src/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";

type InputProps<T extends FieldValues> = Omit<ControllerProps<T>, "render"> &
  FormControlProps & {
    customStyles?: CustomStyles<StylesClasses>;
    isManual?: boolean;
    handleChange?: (e: any) => void;
    loading?: boolean;
    // name: string;
    label?: string;
  };

const Checkbox = <T extends FieldValues>({
  name,
  control,
  label,
  loading,
  rules,
  customStyles,
  isManual,
  handleChange,
  ...rest
}: InputProps<T>) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);

  return (
    <Controller
      render={({ field }) => (
        <FormControlLabel
          {...styles("wrapper")}
          control={
            <MuiCheckbox
              defaultChecked={field.value}
              onChange={(e) => {
                isManual && handleChange(e);
                return field.onChange(e.target.checked);
              }}
              inputRef={field.ref}
              checked={field.value}
              disabled={rest.disabled}
            />
          }
          label={label}
        />
      )}
      name={name}
      control={control}
      rules={rules}
      {...rest}
    />
  );
};
export default Checkbox;
