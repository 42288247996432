import styled from "@emotion/styled";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";
import { useMutation } from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import { deleteCostEstimateDetails } from "src/utils/api/dashboard";

export type DashBoardTable = {
  topHeader: string[];
  data: any;
  onEdit?: (row: any) => void;
  onDelete?: (row: any) => void;
  status?: string;
};

export function CostEstimateDetailsTable({
  data,
  topHeader,
  onEdit,
  onDelete,
  status,
}: DashBoardTable) {
  const styles = getStyles(defaultStyles);

  const calculateRowSpan = (data, key) => {
    const rowSpans = {};
    let count = 0;
    let currentKey = null;

    data.forEach((row, index) => {
      if (row[key] !== currentKey) {
        currentKey = row[key];
        count = 1;
        rowSpans[index] = 1;
      } else {
        count += 1;
        rowSpans[index - count + 1] = count;
      }
    });

    return rowSpans;
  };

  // Calculate row spans for `mainComponent` and `year`
  const mainComponentRowSpan = calculateRowSpan(data, "mainComponent");
  const yearRowSpan = calculateRowSpan(data, "year");

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 600 }} aria-label="simple table">
        <TableHead {...styles("header")}>
          <TableRow>
            {topHeader.map((item, index) => (
              <TableCell align="center" {...styles("cell")} key={index}>
                {item}
              </TableCell>
            ))}
            {status === "DRAFT" && (
              <TableCell align="center" {...styles("cell")}>
                Actions
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            return (
              <TableRow key={index}>
                {yearRowSpan[index] && (
                  <TableCell
                    align="center"
                    rowSpan={yearRowSpan[index]}
                    {...styles("cell", { width: "100px" })}
                  >
                    {row.year}
                  </TableCell>
                )}
                {/* Main Component Column with Row Span */}
                {mainComponentRowSpan[index] && (
                  <TableCell
                    align="center"
                    rowSpan={mainComponentRowSpan[index]}
                    {...styles("cell", { width: "200px" })}
                  >
                    {row.mainComponent}
                  </TableCell>
                )}

                <TableCell
                  align="center"
                  {...styles("cell", { width: "250px" })}
                >
                  {row?.subComponent}
                </TableCell>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "250px" })}
                >
                  {row.perticularOfItem}
                </TableCell>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "100px" })}
                >
                  {row.unit}
                </TableCell>
                {/* <TableCell
                  align="center"
                  {...styles("cell", { width: "100px" })}
                >
                  {row.costPerUnit}
                </TableCell> */}
                <TableCell
                  align="center"
                  {...styles("cell", { width: "100px" })}
                >
                  {row.numberOfUnits}
                </TableCell>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "100px" })}
                >
                  {row.costRequired}
                </TableCell>
                {row.status === "DRAFT" && index !== data.length - 1 && (
                  <TableCell
                    align="center"
                    {...styles("cell", { width: "100px" })}
                  >
                    <>
                      <IconButton aria-label="edit" onClick={() => onEdit(row)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => onDelete(row.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
