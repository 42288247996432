import { Box, Drawer as MuiDrawer } from "@mui/material";
import { CSSObject, styled, Theme } from "@mui/material/styles";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SidebarMenu } from "src/constants/common";
import { useAppSelector } from "src/redux/store";
import { CustomStyles, getStyles } from "src/styles/theme";
import LogoWrapper from "./LogoWrapper";
import defaultStyles, { StylesClasses } from "./styles";

type SideBarProps = {
  customStyles?: CustomStyles<StylesClasses>;
};

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideBar = ({ customStyles }: SideBarProps) => {
  const [open, setOpen] = useState(true);
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  const navigate = useNavigate();
  const location = useLocation();

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const user = useAppSelector((state) => state.root.user);

  return (
    <Drawer variant="permanent" open={open}>
      <Box {...styles("container")}>
        <Box>
          <LogoWrapper onClick={handleDrawerOpen} open={open} />
        </Box>
        <Box>
          <Box {...styles("divider")}></Box>

          {SidebarMenu.filter((item) => {
            return item.type.includes(user.data.userType);
          }).map((item) => {
            return (
              <>
                <Box
                  {...styles("navItemsContainer", {
                    ...(location.pathname === item.path &&
                      defaultStyles.activeNavContainer),
                    paddingLeft: open ? "16px" : "8px",
                  })}
                  onClick={() => {
                    item.path ? navigate(item.path) : console.log("++++");
                  }}
                >
                  <Box component="img" src={item.icon} />
                  <Box
                    {...styles("navText", {
                      ...(location.pathname === item.path &&
                        defaultStyles.activeNavText),
                      display: open ? "block" : "none",
                    })}
                  >
                    {item.text}
                  </Box>
                </Box>
              </>
            );
          })}
        </Box>
      </Box>
    </Drawer>
  );
};

export default SideBar;
