import { Box, CircularProgress } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useSearchParams } from "react-router-dom";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import MapComponent from "src/components/common/KML";
import ViewForm from "src/components/common/ViewForm";
import ViewImages from "src/components/common/ViewImage";
import { useAppSelector } from "src/redux/store";
import { CustomStyles, getStyles } from "src/styles/theme";
import {
  fundApplicationQuery,
  getLandDetailsById,
  getProjectById,
  uploadPaymentReciept,
  verifyFundApplication,
} from "src/utils/api/dashboard";
import defaultStyles, { StylesClasses } from "../styles";
import {
  dateAndTimeStamp,
  downloadSheet,
  formatToIndianCurrency,
} from "src/utils/helper";
import { useNavigate } from "react-router-dom";
import { getFundApplicationById } from "src/utils/api/createProjectFlow";
import { Button } from "src/components/common/FormComponents/Buttons";
import { Input } from "src/components/common/FormComponents/Input";
import { useForm } from "react-hook-form";
import FileUpload from "src/components/common/FormComponents/FileUplaod";
import PdfDocument from "src/components/common/pdf";
import errorMessage from "src/constants/errorMessage";

const FundApplicationDetails = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);
  const [projectData, setProjectData] = useState<any>([]);

  const [searchParams] = useSearchParams();

  const FundApplicationId = searchParams.get("paymentId");

  const user = useAppSelector((state) => state.root.user);

  const { userType, state, division, firstName } = user.data;

  const navigate = useNavigate();

  const {
    data: userData,
    isLoading,
    refetch,
  } = useQuery(
    ["userDetails", FundApplicationId],
    () => getFundApplicationById(FundApplicationId),
    {
      onSuccess: (res: AxiosResponse) => {
        setProjectData(res?.data);
      },
      onError: (req: AxiosError) => {},
      enabled: !!FundApplicationId,
      refetchOnMount: true,
    }
  );

  const { mutate } = useMutation(
    "verifyFundApplication",
    ({
      FundApplicationId,
      data,
    }: {
      FundApplicationId: string;
      data: {
        status: string;
        remark: string;
        paymentApplicationDocId?: string;
      };
    }) =>
      verifyFundApplication({
        FundApplicationId,
        data,
      }),
    {
      onSuccess: () => {
        navigate("/fund-application?currentTab=CREATED");
      },
      onError: () => {},
    }
  );

  const { mutate: uploadReceipt } = useMutation(
    "uploadReceipt",
    ({
      FundApplicationId,
      data,
    }: {
      FundApplicationId: string;
      data: {
        status: string;
        paymentAcknowledgeDocId?: string;
      };
    }) =>
      uploadPaymentReciept({
        FundApplicationId,
        data,
      }),
    {
      onSuccess: () => {
        navigate("/fund-application?currentTab=CREATED");
      },
      onError: () => {},
    }
  );

  const { mutate: mutateQueryData } = useMutation(
    "query",
    ({ id, query }: { id: string; query: string }) =>
      fundApplicationQuery({
        id,
        query,
      }),
    {
      onSuccess: () => {
        refetch();
        setValue("query", "");
      },
      onError: () => {},
    }
  );

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    setError,
    clearErrors,
    trigger,
    reset,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      remarks: "",
      paymentApplicationDocId: [
        {
          file: "",
          key: "",
        },
      ],
    },
  });

  useEffect(() => {
    if (!!projectData) {
      reset({
        remarks: projectData?.fundDetails?.snoRemark,
        paymentApplicationDocId: projectData?.paymentApplicationDocUrl
          ? [
              {
                file: projectData?.paymentApplicationDocUrl,
                key: projectData?.fundDetails?.paymentApplicationDocId,
              },
            ]
          : [],
      });
    }
  }, [projectData]);

  const docId = getValues("paymentApplicationDocId");

  const paymentAcknowledgeDocId = getValues("paymentAcknowledgeDocId");

  const totalArea = Number(projectData?.fundDetails?.landOwnership?.totalArea);

  return (
    <Box>
      {!isLoading ? (
        <DashboardWrapper
          title="Fund Application Details"
          customStyles={{
            container: { position: "relative" },
            children: { paddingBottom: "80px" },
          }}
          showBackBtn
        >
          <Box {...styles("projectName")}>
            Fund Application No : {projectData?.fundDetails?.id}
          </Box>
          <Box {...styles("formWrapper")}>
            <Box {...styles("heading")}>Entity Details</Box>
            <ViewForm
              label="Entity Name"
              value={projectData?.fundDetails?.landOwnership?.user?.firstName}
            />
            <ViewForm
              label="Email"
              value={projectData?.fundDetails?.landOwnership?.user?.email}
            />
            <ViewForm
              label="Phone Number"
              value={`${projectData?.fundDetails?.landOwnership?.user?.phoneNumber}`}
            />
          </Box>
          <Box {...styles("formWrapper")}>
            <Box {...styles("heading")}>Eco-Restoration Blocks Details</Box>
            <ViewForm
              label="Land Registration No"
              value={
                projectData?.fundDetails?.landOwnership?.projectDetail
                  ?.registration_no
              }
            />
            <ViewForm
              label="Cost Estimate ID"
              value={projectData?.fundDetails?.landOwnershipEpid}
            />
            <ViewForm
              label="Eco-Restoration Amount"
              value={formatToIndianCurrency(
                projectData?.fundDetails?.landOwnership?.demandNote
              )}
            />
            <ViewForm
              label="Total Planned Area(Hectares)"
              value={projectData?.fundDetails?.landOwnership?.netPlantationArea}
            />
            <ViewForm
              label="Land Area Requested(Hectares) "
              value={totalArea.toFixed(2)}
            />
            <ViewForm
              label="State"
              value={
                projectData?.fundDetails?.landOwnership?.projectDetail?.address
                  ?.stateDetail?.name
              }
            />
            <ViewForm
              label="District"
              value={
                projectData?.fundDetails?.landOwnership?.projectDetail?.address
                  ?.districtDetail?.name
              }
            />
            <ViewForm
              label="Division"
              value={
                projectData?.fundDetails?.landOwnership?.projectDetail
                  ?.landSpecification?.division
              }
            />
          </Box>

          <Box {...styles("formWrapper")}>
            <Box {...styles("heading")}>
              Estimated Fund Details for 1st Year (As Per Cost Estimate
              Submited)
            </Box>
            <ViewForm
              label="Estimated Advance Work Cost"
              value={`${formatToIndianCurrency(
                projectData?.fundDetails?.estimatedAdvanceWorkCost
              )}`}
            />
            <ViewForm
              label="Estimated Raising Cost"
              value={`${formatToIndianCurrency(
                projectData?.fundDetails?.estimatedRaisingCost
              )}`}
            />
            <ViewForm
              label="Estimated Maintenance Cost"
              value={`${formatToIndianCurrency(
                projectData?.fundDetails?.estimatedMaintenanceCost
              )}`}
            />
            <ViewForm
              label="Estimated Monitoring Cost"
              value={`${formatToIndianCurrency(
                projectData?.fundDetails?.estimatedMonitoringCost
              )}`}
            />
            <ViewForm
              label="Estimated Contingency Cost"
              value={`${formatToIndianCurrency(
                projectData?.fundDetails?.estimatedContingencyCost
              )}`}
            />
            <ViewForm
              label="Total Estimated Cost for 1st Year"
              value={`${formatToIndianCurrency(
                projectData?.fundDetails?.totalEstimatedCost
              )}`}
            />
          </Box>

          <Box {...styles("formWrapper")}>
            <Box {...styles("heading")}>Requested Fund For 1st Year</Box>
            <ViewForm
              label="Advance Work Cost"
              value={`${formatToIndianCurrency(
                projectData?.fundDetails?.advanceWorkCost
              )}`}
            />
            <ViewForm
              label="Raising/Creation Cost"
              value={`${formatToIndianCurrency(
                projectData?.fundDetails?.raisingCost
              )}`}
            />
            <ViewForm
              label="Maintenance Cost"
              value={`${formatToIndianCurrency(
                projectData?.fundDetails?.maintenanceCost
              )}`}
            />
            <ViewForm
              label="Monitoring Cost"
              value={`${formatToIndianCurrency(
                projectData?.fundDetails?.monitoringCost
              )}`}
            />
            <ViewForm
              label="Contingency Cost"
              value={`${formatToIndianCurrency(
                projectData?.fundDetails?.contingencyCost
              )}`}
            />
            <ViewForm
              label="Total Amount Requested"
              value={`${formatToIndianCurrency(
                projectData?.fundDetails?.totalCost
              )}`}
            />
            {projectData?.fundDetails?.totalAmountReleased && (
              <ViewForm
                label="Total Amount Released By Admin"
                value={`${formatToIndianCurrency(
                  projectData?.fundDetails?.totalAmountReleased
                )}`}
              />
            )}
            {projectData?.fundDetails?.amountLeft && (
              <ViewForm
                label="Balance Amount"
                value={`${formatToIndianCurrency(
                  projectData?.fundDetails?.amountLeft
                )}`}
              />
            )}
          </Box>

          <Box {...styles("formWrapper")}>
            <Box {...styles("heading")}>Additional Information</Box>
            <ViewForm
              label="Additional Information"
              value={projectData?.fundDetails?.additionalInfo}
            />
            {projectData?.fundDetails?.snoRemark && (
              <ViewForm
                label="SNO Remark"
                value={projectData?.fundDetails?.snoRemark}
              />
            )}
            {projectData?.fundDetails?.remark && (
              <ViewForm
                label="Remark"
                value={projectData?.fundDetails?.remark}
              />
            )}
          </Box>

          {projectData?.paymentApplicationDocUrl && (
            <Box {...styles("formWrapper")}>
              <Box {...styles("heading")}>Signed Fund Application From SNO</Box>
              <Box {...styles("documentGroup")}>
                <ViewImages
                  fileUrl={projectData?.paymentApplicationDocUrl}
                  label={""}
                />
              </Box>
            </Box>
          )}

          {projectData?.applicationApproveDocUrl && (
            <Box {...styles("formWrapper")}>
              <Box {...styles("heading")}>
                Signed Fund Application From Admin
              </Box>
              <Box {...styles("documentGroup")}>
                <ViewImages
                  fileUrl={projectData?.applicationApproveDocUrl}
                  label={""}
                />
              </Box>
            </Box>
          )}

          {projectData?.paymentAcknowledgementDocUrl && (
            <Box {...styles("formWrapper")}>
              <Box {...styles("heading")}>Transaction Receipt</Box>
              <Box {...styles("documentGroup")}>
                <ViewImages
                  fileUrl={projectData?.paymentAcknowledgementDocUrl}
                  label={""}
                />
              </Box>
            </Box>
          )}

          {projectData?.fundDetails?.queries?.length > 0 && (
            <Box {...styles("formWrapper")}>
              <Box {...styles("heading")}>Fund Transfer Query</Box>
              {projectData?.fundDetails?.queries.map((item) => (
                <Box sx={{ paddingTop: "20px" }}>
                  <ViewForm
                    label={item?.user?.firstName}
                    value={item?.query}
                    date={dateAndTimeStamp(item.created_at, "IST")}
                  />
                </Box>
              ))}
            </Box>
          )}

          {(projectData?.fundDetails?.status === "CREATED" ||
            projectData?.fundDetails?.status === "UNDER_QUERY") &&
            userType === "INDIVIDUAL" && (
              <Box>
                <Box {...styles(["input", "fileUploads"])}>
                  <FileUpload
                    key={watch("paymentApplicationDocId")?.[0]?.key}
                    name="paymentApplicationDocId"
                    label="Upload Signed Fund Application"
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    getValues={getValues}
                    setError={setError}
                    clearErrors={clearErrors}
                    maxLimit={1}
                    required={true}
                    trigger={trigger}
                    customStyles={{
                      wrapper: defaultStyles.borderStyle,
                      fileType: defaultStyles.fileType,
                      label: {
                        whiteSpace: "wrap",
                      },
                    }}
                    rules={{
                      validate: {
                        isLength: (value) => {
                          return value && value["length"] >= 1;
                        },
                      },
                    }}
                    dragDropHeading=""
                  />
                </Box>
                <Box>
                  <Box sx={{ marginTop: "30px" }}>
                    <Input
                      name="query"
                      label="Query"
                      control={control}
                      errors={errors}
                      multiline
                      height="360px"
                      customStyles={{
                        input: {
                          height: "auto",
                          fontFamily: "Poppins",
                          width: { sm: "100%", lg: "60%" },
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ marginTop: "30px" }}>
                    <Input
                      name="remarks"
                      label="SNO Remarks"
                      control={control}
                      errors={errors}
                      multiline
                      height="360px"
                      required={true}
                      rules={{ required: errorMessage.required }}
                      customStyles={{
                        input: {
                          height: "auto",
                          fontFamily: "Poppins",
                          width: { sm: "100%", lg: "60%" },
                        },
                      }}
                    />
                  </Box>
                  <Box {...styles("btnWrapper")}>
                    <Button
                      text="Reject"
                      variant="outlined"
                      onClick={() => {
                        mutate({
                          FundApplicationId,
                          data: {
                            status: "REJECTED",
                            remark: watch("remarks"),
                          },
                        });
                      }}
                      customStyles={{
                        root: {
                          fontSize: "18px",
                          color: "error.main",
                          borderColor: "error.main",
                        },
                      }}
                    />
                    <Button
                      text="Forward To Admin"
                      type="submit"
                      disabled={!isValid}
                      onClick={() => {
                        mutate({
                          FundApplicationId,
                          data: {
                            status: "RAISED",
                            remark: watch("remarks"),
                            paymentApplicationDocId: docId[0]?.key,
                          },
                        });
                      }}
                      customStyles={{
                        root: {
                          fontSize: "16px",
                          textWrap: "noWrap",
                        },
                      }}
                    />
                  </Box>
                  <Button
                    text="Raise Query"
                    onClick={() => {
                      mutateQueryData({
                        id: FundApplicationId,
                        query: watch("query"),
                      });
                    }}
                    disabled={!watch("query")}
                    customStyles={{
                      root: {
                        fontSize: "16px",
                        position: "absolute",
                        bottom: "20px",
                      },
                    }}
                  />
                </Box>
              </Box>
            )}

          {projectData?.fundDetails?.status === "APPROVED" &&
            userType === "INDIVIDUAL" && (
              <Box>
                <Box {...styles(["input", "fileUploads"])}>
                  <FileUpload
                    name="paymentAcknowledgeDocId"
                    label="Upload Transaction Receipt"
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    getValues={getValues}
                    setError={setError}
                    clearErrors={clearErrors}
                    maxLimit={1}
                    required={true}
                    trigger={trigger}
                    customStyles={{
                      wrapper: defaultStyles.borderStyle,
                      fileType: defaultStyles.fileType,
                      label: {
                        whiteSpace: "wrap",
                      },
                    }}
                    rules={{
                      validate: {
                        isLength: (value) => {
                          return value && value["length"] >= 1;
                        },
                      },
                    }}
                    dragDropHeading=""
                  />
                </Box>
                <Box>
                  <Box {...styles("btnWrapper")}>
                    <Button
                      text="Upload Transaction Receipt"
                      // type="upload"
                      disabled={!isValid}
                      onClick={() => {
                        uploadReceipt({
                          FundApplicationId,
                          data: {
                            status: "PAYMENT_ACKNOWLEDGED",
                            paymentAcknowledgeDocId:
                              paymentAcknowledgeDocId[0]?.key,
                          },
                        });
                      }}
                      customStyles={{
                        root: {
                          fontSize: "16px",
                          textWrap: "noWrap",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            )}
        </DashboardWrapper>
      ) : (
        <Box {...styles("loading")}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default FundApplicationDetails;
