import { Box, Dialog } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import { Button } from "src/components/common/FormComponents/Buttons";
import { useForm } from "react-hook-form";
import {
  getAllRegistration,
  getCostEstimateDetails,
  getLandDetailsById,
} from "src/utils/api/createProjectFlow";
import defaultStyles, { StylesClasses } from "./styles";
import AutoComplete from "src/components/common/FormComponents/AutoComplete";
import errorMessage from "src/constants/errorMessage";
import { getStyles } from "src/styles/theme";
import {
  CostEstimateDetailsTable,
  DashBoardTable,
} from "src/components/common/DashboardTable/costEstimateDetails";
import ViewForm from "src/components/common/ViewForm";
import { formatToIndianCurrency } from "src/utils/helper";
import { EditCostEstimateForm } from "src/components/feature/CostEstiamteDetails/EditCostEstimateForm";
import {
  deleteCostEstimateDetails,
  updateStatusCostEstimateDetails,
} from "src/utils/api/dashboard";
import SuccessModal from "src/components/common/modal/Success";
import EcoRestorationProgress from "../DemandNoteViewProgress";
import { CostEstimateSummary } from "src/components/common/DashboardTable/costEstimateSummary";
import ViewImages from "src/components/common/ViewImage";
import { useReactToPrint } from "react-to-print";
import { SeedlingTable } from "src/components/common/DashboardTable/seedlingTable";

const CostEstimateDetails = () => {
  const componentRef = useRef();

  const styles = getStyles<StylesClasses>(defaultStyles);

  const [yearTableRows, setYearTableRows] = useState({});

  const [yearSummaryTableRows, setYearSummaryTableRows] = useState([]);
  const [seedlingTableData, setSeedlingTableData] = useState([]);

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const [isAutoCompleteSelected, setIsAutoCompleteSelected] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [finalSubmitState, setFinalSubmitState] = useState(false);

  const [showForm, setShowForm] = useState(false);

  const [queryParams, setQueryParams] = useState<{
    status: string;
    offset: number;
    limit: number;
    download: boolean;
    sortBy: string;
    searchText: string;
    landOwnershipEpid: string;
  }>({
    status: "APPROVED",
    offset: 0,
    limit: 300,
    download: false,
    sortBy: "updated_at:DESC",
    searchText: "",
    landOwnershipEpid: "",
  });

  const [progressQueryParams, setProgressQueryParams] = useState<{
    landOwnershipEpid: string;
  }>({
    landOwnershipEpid: "",
  });

  const { data: projectDetails } = useQuery(
    ["fundFlow", queryParams],
    () =>
      getAllRegistration({
        ...queryParams,
      }),
    {
      onSuccess: (res: AxiosResponse) => {},

      onError: (err: AxiosError) => {},
      refetchOnMount: true,
    }
  );

  const parseData = projectDetails?.data?.rows?.map((item) => ({
    label: `${item.registrationNo}_${item.id}_Range: ${item?.projectDetail?.landSpecification?.range}_Area: ${item.landArea}`,
    value: item.id,
  }));

  const { data: userData } = useQuery(
    ["userDetails", progressQueryParams.landOwnershipEpid],
    () => getLandDetailsById(progressQueryParams.landOwnershipEpid),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: (req: AxiosError) => {},
      enabled: !!progressQueryParams.landOwnershipEpid,
      refetchOnMount: true,
    }
  );

  const deleteUserFun = useMutation(
    "deleteCostEstimateDetails",
    (data: string) => deleteCostEstimateDetails(data),
    {
      onSuccess: (res: AxiosResponse) => {
        setIsSuccessModalOpen(false);
        refetch();
      },
      onError: (err: AxiosError) => {
        setIsSuccessModalOpen(false);
        refetch();
      },
    }
  );

  const handleDeleteModal = (row) => {
    setRowToDelete(row);
    setIsSuccessModalOpen(true); // Open the success modal
  };

  const { mutate: changeProjectStatus, isLoading: isUpdatingStatus } =
    useMutation(
      (data: { landOwnershipEpid: string; status: string }) =>
        updateStatusCostEstimateDetails(data),
      {
        onSuccess: (response) => {
          console.log("Project status updated successfully:", response);
        },
        onError: (error) => {
          console.error("Error updating project status:", error);
        },
      }
    );

  const mapYearData = (yearTargets) => {
    return yearTargets?.map((row) => ({
      data: row,
      id: row.id,
      sno: row.serialNumber,
      year: row?.year,
      costEstimateID: row?.landOwnershipEpid,
      mainComponent: row?.indicator?.name,
      subComponent: row?.activity?.name,
      perticularOfItem: row?.perticularOfItem,
      unit: row?.unit,
      costPerUnit: row?.costPerUnit,
      numberOfUnits: row?.numberOfUnits,
      costRequired: row?.costRequired,
      status: row?.status,
    }));
  };

  const { data: targetData, refetch } = useQuery(
    ["costEstimateDetails", progressQueryParams],
    () =>
      getCostEstimateDetails({
        ...progressQueryParams,
      }),
    {
      onSuccess: (res: AxiosResponse) => {
        const yearData = {
          firstYear: mapYearData(res?.data?.data?.firstYearTargets),
          secondYear: mapYearData(res?.data?.data?.secondYearTargets),
          thirdYear: mapYearData(res?.data?.data?.thirdYearTargets),
          fourthYear: mapYearData(res?.data?.data?.fourthYearTargets),
          fifthYear: mapYearData(res?.data?.data?.fifthYearTargets),
          sixthYear: mapYearData(res?.data?.data?.sixthYearTargets),
          seventhYear: mapYearData(res?.data?.data?.seventhYearTargets),
          eighthYear: mapYearData(res?.data?.data?.eighthYearTargets),
          ninthYear: mapYearData(res?.data?.data?.ninthYearTargets),
          tenthYear: mapYearData(res?.data?.data?.tenthYearTargets),
          other: mapYearData(res?.data?.data?.otherNecessaryProvisions),
        };
        setYearSummaryTableRows([
          res?.data?.data?.firstYearTotalCostRequired,
          res?.data?.data?.secondYearTotalCostRequired,
          res?.data?.data?.thirdYearTotalCostRequired,
          res?.data?.data?.fourthYearTotalCostRequired,
          res?.data?.data?.fifthYearTotalCostRequired,
          res?.data?.data?.sixthYearTotalCostRequired,
          res?.data?.data?.seventhYearTotalCostRequired,
          res?.data?.data?.eighthYearTotalCostRequired,
          res?.data?.data?.ninthYearTotalCostRequired,
          res?.data?.data?.tenthYearTotalCostRequired,
          res?.data?.data?.otherNecessaryProvisionsTotalCostRequired,
        ]);
        setSeedlingTableData(res?.data?.data?.seedlings);
        setYearTableRows(yearData);
      },
      onError: (err: AxiosError) => {},
      refetchOnMount: true,
    }
  );

  const isAllYearDataFilled = yearSummaryTableRows
    .slice(0, 10)
    .every((yearData) => yearData !== null && yearData !== undefined);

  const {
    control,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      landOwnershipEpid: "",
    },
  });

  const commonTableHeaders = [
    "Year",
    "Main Component",
    "Sub Component",
    "Particulars of item of works",
    "Unit",
    "No of Unit Required",
    "Cost Required(Rs.)",
  ];

  const commonSummaryHeaders = ["Year", "Cost Required"];
  const commonSeedlingHeader = [
    "Common Name",
    "Botanical Name",
    "No of Seedlings",
  ];

  const [RowData, setRowData] = useState();
  const [editFormState, setEditFormState] = useState<boolean>(false);
  const handleCostEstimateTableEdit = (row) => {
    setEditFormState(true);
    setRowData(row);
  };

  const renderYearlyTables = () => {
    return Object.keys(yearTableRows).map((yearKey, index) => {
      if (yearKey === "yearSummary") return;
      const tableData: DashBoardTable = {
        topHeader: commonTableHeaders,
        data: yearTableRows[yearKey],
      };

      if (yearTableRows[yearKey]?.length > 0) {
        return (
          <Box key={yearKey} sx={{ marginBottom: "30px", marginTop: "30px" }}>
            <CostEstimateDetailsTable
              {...tableData}
              onEdit={handleCostEstimateTableEdit}
              onDelete={handleDeleteModal}
              status={targetData?.data?.data?.status}
            />
          </Box>
        );
      }
      return null;
    });
  };

  const renderSummaryTable = () => {
    const summaryData: DashBoardTable = {
      topHeader: commonSummaryHeaders,
      data: yearSummaryTableRows,
    };
    const seedlingData: DashBoardTable = {
      topHeader: commonSeedlingHeader,
      data: seedlingTableData,
    };

    if (yearSummaryTableRows) {
      return (
        <Box
          sx={{
            marginBottom: "30px",
            marginTop: "30px",
            display: "flex",
            gap: "20px",
          }}
        >
          <CostEstimateSummary
            {...summaryData}
            onEdit={handleCostEstimateTableEdit}
            onDelete={handleDeleteModal}
            status={targetData?.data?.data?.status}
          />
          <SeedlingTable {...seedlingData} />
        </Box>
      );
    }
  };

  const bottomRef = useRef(null);

  const moveToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleRedirect = () => {
    moveToBottom();
    setShowForm(true);
  };

  const handleAddForm = () => {
    setShowForm(false);
    refetch();
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: "@media print { body { -webkit-print-color-adjust: exact; } }",
  });

  const epiddata = userData?.data;

  return (
    <>
      <DashboardWrapper
        title="Cost Estimate Details"
        buttonText="Print"
        onButtonClick={handlePrint}
        showBtn={true}
      >
        <>
          <Box>
            <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <Box {...styles("input")}>
                <AutoComplete
                  options={parseData}
                  onOptionSelect={(e) => {
                    if (parseData?.length && e) {
                      setProgressQueryParams({
                        landOwnershipEpid: e.value,
                      });
                      setIsAutoCompleteSelected(true);
                    }
                  }}
                  rules={{ required: errorMessage.required }}
                  customStyles={{
                    label: defaultStyles.label,
                    wrapper: defaultStyles.selectWrapper,
                    input: {
                      borderColor: "#36B1A0;",
                      boxShadow: "unset",
                      borderWidth: "2px",
                    },
                  }}
                  name="landOwnershipEpid"
                  control={control}
                  placeholder="Please Select Eco-Restoration Block for details"
                  label="Please Select Eco-Restoration Block to View Cost Estimate Details"
                />
              </Box>

              {targetData?.data?.data?.status === "DRAFT" &&
                Object.values(yearTableRows).flat().length > 0 && (
                  <>
                    <Button
                      text="Final Submit"
                      type="submit"
                      disabled={!isAllYearDataFilled}
                      customStyles={{
                        root: {
                          fontSize: "16px",
                          height: "40px",
                        },
                      }}
                      onClick={() => setFinalSubmitState(true)}
                    />

                    <Button
                      text="Add More Details"
                      onClick={handleRedirect}
                      customStyles={{
                        root: {
                          fontSize: "16px",
                          height: "40px",
                        },
                      }}
                    />
                  </>
                )}
            </Box>

            {isAutoCompleteSelected && (
              <Box ref={componentRef} id="printable-content">
                <Box
                  sx={{
                    fontSize: "22px",
                    textAlign: "center",
                    marginTop: "26px",
                    fontWeight: "bold",
                  }}
                >
                  Cost Estimate for eco-restoration of degraded forest lands
                  under the Green Credit Programme
                </Box>
                <Box {...styles("formWrapper")}>
                  <Box sx={{ width: "40%" }}>
                    <ViewForm
                      label={"Registration No"}
                      value={epiddata?.projectDetail?.registrationNo}
                    />
                    <ViewForm label={"EPID"} value={epiddata?.id} />
                    <ViewForm
                      label={"State"}
                      value={
                        epiddata?.projectDetail?.address?.stateDetail?.name
                      }
                    />
                    <ViewForm
                      label={"District"}
                      value={
                        epiddata?.projectDetail?.address?.districtDetail?.name
                      }
                    />
                    <ViewForm
                      label={"Division"}
                      value={
                        epiddata?.projectDetail?.landSpecification?.division
                      }
                    />
                    <ViewForm
                      label={"Range"}
                      value={epiddata?.projectDetail?.landSpecification?.range}
                    />
                    <ViewForm
                      label={"Beat"}
                      value={epiddata?.projectDetail?.landSpecification?.beat}
                    />
                  </Box>

                  <Box sx={{ width: "40%" }}>
                    <ViewForm
                      label={"Total Area(ha)"}
                      value={
                        epiddata?.projectDetail?.landSpecification?.totalArea
                      }
                    />
                    <ViewForm
                      label={"Net Plantation Area(ha)"}
                      value={epiddata?.netPlantationArea}
                    />
                    <ViewForm
                      label={"Eco-Restoration Cost"}
                      value={formatToIndianCurrency(epiddata?.demandNote)}
                    />
                    {targetData?.data?.data?.plantationPlanning?.pitSize && (
                      <>
                        <ViewForm
                          label={"Sapling spacing(m X m)"}
                          value={`${targetData?.data?.data?.plantationPlanning?.treeSeedlingPacingX} X ${targetData?.data?.data?.plantationPlanning?.treeSeedlingPacingY}`}
                        />
                        <ViewForm
                          label={"Pit Size"}
                          value={
                            targetData?.data?.data?.plantationPlanning?.pitSize
                          }
                        />
                        <ViewForm
                          label={"Total No. of Saplings"}
                          value={
                            targetData?.data?.data?.plantationPlanning
                              ?.totalNumberOfSeedlingToBePlanted
                          }
                        />
                      </>
                    )}
                  </Box>

                  <Box sx={{ width: "30%" }}>
                    <Box
                      sx={{
                        fontSize: "17px",
                        fontWeight: "bold",
                      }}
                    >
                      Technically sanctioned/approved cost estimate
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      <Box {...styles("documentGroup")}>
                        <ViewImages
                          fileUrl={epiddata?.paymentBreakdownPdfImage}
                          label={"PDF"}
                        />
                      </Box>
                      <Box {...styles("documentGroup")}>
                        <ViewImages
                          fileUrl={epiddata?.paymentBreakdownImage}
                          label={"Ms Excel"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {Object.values(yearTableRows).flat().length > 0 ? (
                  <>
                    {/* Render all year tables */}
                    {renderYearlyTables()}
                    {renderSummaryTable()}
                  </>
                ) : (
                  <></>
                )}
              </Box>
            )}
          </Box>
          <Box ref={bottomRef}>
            {(showForm ||
              (Object.values(yearTableRows).flat().length === 0 &&
                isAutoCompleteSelected)) && (
              <EcoRestorationProgress
                projectId={progressQueryParams.landOwnershipEpid}
                onSubmit={handleAddForm}
                showInfo={Object.values(yearTableRows).flat().length === 0}
              />
            )}
          </Box>
        </>

        <Dialog
          open={editFormState}
          onClose={() => {
            setEditFormState(false);
            refetch();
          }}
          fullWidth
          maxWidth="lg"
          sx={{
            "& .MuiDialog-paper": {
              width: "100%",
              borderRadius: "16px",
            },
          }}
        >
          <EditCostEstimateForm
            rowData={RowData}
            selfState={setEditFormState}
            refetch={refetch}
          />
        </Dialog>
        <SuccessModal
          open={finalSubmitState}
          heading="Are you sure you want to Final Submit Cost Estimate Details? After the Final submit Cost Estimate Details cannot be editable"
          btnText="Submit"
          onClick={() => {
            changeProjectStatus({
              landOwnershipEpid: progressQueryParams.landOwnershipEpid,
              status: "SUBMIT_TO_SNO",
            });
            setFinalSubmitState(false);
            refetch();
          }}
          handleModalClose={() => setFinalSubmitState(false)}
        />
      </DashboardWrapper>
      <SuccessModal
        open={isSuccessModalOpen}
        heading="Are you sure you want to delete this cost estimate detail?"
        type="warning"
        btnText="Delete"
        onClick={() => {
          deleteUserFun.mutate(rowToDelete);
          setIsSuccessModalOpen(false);
        }}
        handleModalClose={() => setIsSuccessModalOpen(false)}
      />
    </>
  );
};

export default CostEstimateDetails;
