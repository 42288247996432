export type PathType<T extends string = string> = Record<T, string>;
const createPath = <T extends string>(data: PathType<T>) => data;

export const path = createPath({
  home: "/",
  notFound: "/not-found",
  adminLogin: "/admin/login",
  signIn: "/login",
  privacyPolicy: "/privacy-policy",
  onboarding: "/onboarding",
  personalDetails: "/onboarding/personal-details",
  nodalOfficerDetails: "/onboarding/nodal-officer-details",
  implementingOfficerDetails: "/onboarding/implementing-officer-details",
  bankDetails: "/onboarding/bank-details",
  contactDetails: "/onboarding/contact-details",
  entityDetails: "/onboarding/entity-details",
  agencyDetails: "/onboarding/agency-details",
  addressDetails: "/onboarding/address-details",
  aadhaarVerification: "/onboarding/aadhaar-verification",
  organizationDetails: "/onboarding/organization-details",
  documentUpload: "/onboarding/document-upload",
  marketWatch: "/market-watch",
  login: "/login",
  forgetPassword: "/forget-password",
  myProfile: "/my-profile",
  projectDetailView: "/activity/details",
  resetPassword: "/reset-password",
  demandNote: "/demand-note",
  orderBook: "/order-book",
  demandNoteView: "/view-demand-draft",
  landOwnerShipDetailView: "/landownership-details",
  addImplementingOfficer: "/add-implementing-officer",
  changePassword: "/change-password",
  fundApplication: "/fund-application",
  fillFundApplication: "/fill-fund-application",
  fundApplicationDetails: "/fund-application-details",
  viewFundApplicationDetails: "/view-fund-application",
  ecoRestorationProgress: "/ecorestoration-progress",
  costEstimateDetails: "/cost-estimate-details",
});
