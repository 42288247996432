import { Box } from "@mui/material";
import { useForm, useFieldArray } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import {
  AutoComplete,
  Button,
  Input,
  Select,
} from "src/components/common/FormComponents";
import { getLandDetailsById } from "src/utils/api/createProjectFlow";
import {
  addCostestimateDetails,
  getProgressActivities,
  getProgressIndicators,
  getProgressYear,
} from "src/utils/api/dashboard";
import { useState } from "react";
import { calenderYear, units } from "src/constants/common";
import { optionTypeFormatter } from "src/utils/helper";
import errorMessage from "src/constants/errorMessage";
import defaultStyles, { StylesClasses } from "./styles";
import { getStyles } from "src/styles/theme";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { getValue } from "@testing-library/user-event/dist/utils";

const EcoRestorationProgress = ({ projectId, onSubmit, showInfo }) => {
  const styles = getStyles<StylesClasses>(defaultStyles);

  const [projectData, setProjectData] = useState<any>([]);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      landOwnershipEpid: "",
      indicatorId: "",
      subComponents: [
        {
          activityId: "",
          perticularOfItem: "",
          unit: "",
          costPerUnit: "",
          numberOfUnits: "",
          costRequired: "",
        },
      ],
      seedlings: [{}],
    },
  });

  // Using useFieldArray for dynamic sub-components
  const { fields, append, remove } = useFieldArray({
    control,
    name: "subComponents",
  });

  const {
    fields: seedlingFields,
    append: appendSeedling,
    remove: removeSeedling,
  } = useFieldArray({
    control,
    name: "seedlings",
  });

  const { mutate } = useMutation(
    "fillFundApllicationForm",
    ({ data }: { data: any }) => addCostestimateDetails(data),
    {
      onSuccess: () => {
        reset();
        onSubmit();
      },
      onError: (error: any) => {},
    }
  );

  const selectedIndicatorType = watch("indicatorId");

  const { data: progressIndictors } = useQuery(
    ["progressIndictors"],
    getProgressIndicators,
    {
      refetchOnMount: true,
    }
  );

  const Indicators = optionTypeFormatter(progressIndictors?.data?.data);

  const { data: activities } = useQuery(
    ["activities", selectedIndicatorType],
    () => getProgressActivities(selectedIndicatorType),
    {
      enabled: !!selectedIndicatorType,
    }
  );

  const ProgressActivities = optionTypeFormatter(activities?.data?.data);

  const { data: userData } = useQuery(
    ["userDetails", projectId],
    () => getLandDetailsById(projectId),
    {
      onSuccess: (res) => setProjectData(res?.data),
      enabled: !!projectId,
      refetchOnMount: true,
    }
  );

  const { data: progressYear } = useQuery(["progressYear"], getProgressYear, {
    refetchOnMount: true,
  });

  const handleFinalSubmit = (data: any) => {
    mutate({
      data: {
        ...data,
        landOwnershipEpid: projectId,
        subComponents: data.subComponents.map((component: any) => ({
          activityId: component.activityId,
          numberOfUnits: +component.numberOfUnits,
          costRequired: +component.costRequired,
          unit: component.unit,
          costPerUnit: +component.costPerUnit,
          perticularOfItem: component.perticularOfItem,
        })),
        seedlings: data.seedlings.map((seedling: any) => ({
          commonName: seedling.commonName,
          botanicalName: seedling.botanicalName,
          numberOfSeedlings: +seedling.numberOfSeedlings,
        })),
        targetType: "Target",
      },
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(handleFinalSubmit)}>
      {!projectData?.plantationPlanning && (
        <>
          <Box {...styles("wrapper")}>
            <Box>
              <label
                htmlFor="flex"
                style={{
                  fontWeight: "600",
                  marginBottom: "8px",
                  marginTop: "8px",
                  fontSize: "14px",
                  display: "block",
                  color: "black",
                }}
              >
                Sapling spacing{" "}
                <span
                  style={{
                    color: "red",
                  }}
                >
                  *
                </span>
              </label>
              <Box {...styles("flexInput")} id="flex">
                <Input
                  customStyles={{
                    label: defaultStyles.label,
                    wrapper: defaultStyles.selectWrapper,
                    input: {
                      borderColor: "#36B1A0;",
                      boxShadow: "unset",
                    },
                  }}
                  name="treeSeedlingPacingX"
                  rules={{ required: errorMessage.required }}
                  control={control}
                  errors={errors}
                  placeholder="meter"
                  type="number"
                />
                {"X"}
                <Input
                  customStyles={{
                    label: defaultStyles.label,
                    wrapper: defaultStyles.selectWrapper,
                    input: {
                      borderColor: "#36B1A0;",
                      boxShadow: "unset",
                    },
                  }}
                  name="treeSeedlingPacingY"
                  rules={{ required: errorMessage.required }}
                  control={control}
                  errors={errors}
                  placeholder="meter"
                  type="number"
                />
              </Box>
            </Box>
            <Box {...styles("fullInput")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                  input: {
                    borderColor: "#36B1A0;",
                    boxShadow: "unset",
                  },
                }}
                name="pitSize"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Enter Pit Size in cubic meter"
                label="Pit Size"
              />
            </Box>
            <Box {...styles("fullInput")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                  input: {
                    borderColor: "#36B1A0;",
                    boxShadow: "unset",
                  },
                }}
                name="totalNumberOfSeedlingToBePlanted"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Enter Total No. of Saplings"
                label="Total No. of Saplings"
                type="number"
              />
            </Box>

            {seedlingFields.map((item, index) => (
              <Box key={item.id} {...styles("subSeedlingFormWrapper")}>
                <Box {...styles("fullInput")}>
                  <Input
                    customStyles={{
                      label: defaultStyles.label,
                      wrapper: defaultStyles.selectWrapper,
                      input: {
                        borderColor: "#36B1A0;",
                        boxShadow: "unset",
                      },
                    }}
                    name={`seedlings[${index}].commonName`}
                    rules={{ required: errorMessage.required }}
                    control={control}
                    errors={errors}
                    placeholder="Enter Common Name"
                    label="Common Name"
                  />
                </Box>
                <Box {...styles("fullInput")}>
                  <Input
                    customStyles={{
                      label: defaultStyles.label,
                      wrapper: defaultStyles.selectWrapper,
                      input: {
                        borderColor: "#36B1A0;",
                        boxShadow: "unset",
                      },
                    }}
                    name={`seedlings[${index}].botanicalName`}
                    control={control}
                    errors={errors}
                    placeholder="Enter Botanical Name"
                    label="Botanical Name"
                  />
                </Box>
                <Box {...styles("fullInput")}>
                  <Input
                    customStyles={{
                      label: defaultStyles.label,
                      wrapper: defaultStyles.selectWrapper,
                      input: {
                        borderColor: "#36B1A0;",
                        boxShadow: "unset",
                      },
                    }}
                    name={`seedlings[${index}].numberOfSeedlings`}
                    rules={{ required: errorMessage.required }}
                    control={control}
                    errors={errors}
                    placeholder="Enter Number of Seedlings"
                    label="Number of Seedlings"
                    type="number"
                  />
                </Box>
                <DeleteRoundedIcon
                  onClick={() => removeSeedling(index)}
                  sx={{ cursor: "pointer", color: "grey" }}
                />
              </Box>
            ))}
            <Button
              text="Add Species Wise no. of Seedling to be Planted"
              onClick={() =>
                appendSeedling({
                  commonName: "",
                  botanicalName: "",
                  numberOfSeedlings: "",
                })
              }
              customStyles={{
                root: { marginTop: "20px", height: "40px" },
              }}
            />
          </Box>
        </>
      )}
      <Box {...styles("wrapper")}>
        <Box {...styles("input")}>
          <Select
            options={progressYear?.data?.data || []}
            sx={{
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#36B1A0;",
              boxShadow: "unset",
              marginTop: "10px",
              borderRadius: "10px",
            }}
            name="year"
            label="Select Year"
            control={control}
            rules={{ required: "This field is required" }}
            placeholder="Select Year"
          />
        </Box>
        <Box {...styles("input")}>
          <AutoComplete
            options={calenderYear}
            customStyles={{
              label: defaultStyles.label,
              wrapper: defaultStyles.selectWrapper,
              input: {
                borderColor: "#36B1A0;",
                boxShadow: "unset",
              },
            }}
            rules={{ required: "This field is required" }}
            name="financialYear"
            control={control}
            placeholder="Select Financial Year"
            label="Select Financial Year"
          />
        </Box>
        <Box {...styles("input")}>
          <AutoComplete
            options={Indicators}
            customStyles={{
              label: defaultStyles.label,
              wrapper: defaultStyles.selectWrapper,
              input: {
                borderColor: "#36B1A0;",
                boxShadow: "unset",
              },
            }}
            rules={{ required: "This field is required" }}
            name="indicatorId"
            control={control}
            placeholder="Select Main Component"
            label="Select Main Component"
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FAFAFA",
          gap: "20px",
          padding: "30px",
          borderRadius: "32px",
        }}
      >
        {fields.map((item, index) => (
          <Box
            {...styles("subComponentFormWrapper", {
              display: "flex",
              flexDirection: "row-reverse",
              flexWrap: "nowrap",
              alignItems: "start",
            })}
          >
            <DeleteRoundedIcon
              onClick={() => remove(index)}
              sx={{
                cursor: "pointer",
                color: "grey",
                width: "30px",
                height: "30px",
              }}
            />
            <Box
              key={item.id}
              {...styles("subComponentFormWrapper", {
                border: "none",
              })}
            >
              <Box {...styles("input")}>
                <AutoComplete
                  options={ProgressActivities}
                  rules={{ required: errorMessage.required }}
                  customStyles={{
                    label: defaultStyles.label,
                    wrapper: defaultStyles.selectWrapper,
                    input: {
                      borderColor: "#36B1A0;",
                      boxShadow: "unset",
                    },
                  }}
                  name={`subComponents[${index}].activityId`}
                  control={control}
                  placeholder="Select Sub Component"
                  label="Select Sub Component"
                />
              </Box>
              <Box {...styles("fullWidhtInput")}>
                <Input
                  customStyles={{
                    label: defaultStyles.label,
                    wrapper: defaultStyles.selectWrapper,
                    input: {
                      borderColor: "#36B1A0;",
                      boxShadow: "unset",
                    },
                  }}
                  name={`subComponents[${index}].perticularOfItem`}
                  rules={{ required: errorMessage.required }}
                  control={control}
                  errors={errors}
                  placeholder="Particulars of item of works as per cost estimate"
                  label="Particulars of item of works as per cost estimate"
                />
              </Box>
              <Box {...styles("fullInput")}>
                <AutoComplete
                  options={units}
                  rules={{ required: errorMessage.required }}
                  customStyles={{
                    label: defaultStyles.label,
                    wrapper: defaultStyles.selectWrapper,
                    input: {
                      borderColor: "#36B1A0;",
                      boxShadow: "unset",
                    },
                  }}
                  name={`subComponents[${index}].unit`}
                  control={control}
                  placeholder="Select Units"
                  label="Select Unit"
                />
              </Box>
              {/* <Box {...styles("fullInput")}>
                <Input
                  customStyles={{
                    label: defaultStyles.label,
                    wrapper: defaultStyles.selectWrapper,
                    input: {
                      borderColor: "#36B1A0;",
                      boxShadow: "unset",
                    },
                  }}
                  name={`subComponents[${index}].costPerUnit`}
                  rules={{ required: errorMessage.required }}
                  control={control}
                  errors={errors}
                  placeholder="Enter Cost Incurred(Rs.)"
                  label="Cost/unit(Rs.)"
                  type="number"
                />
              </Box> */}
              <Box {...styles("fullInput")}>
                <Input
                  customStyles={{
                    label: defaultStyles.label,
                    wrapper: defaultStyles.selectWrapper,
                    input: {
                      borderColor: "#36B1A0;",
                      boxShadow: "unset",
                    },
                  }}
                  name={`subComponents[${index}].numberOfUnits`}
                  rules={{ required: errorMessage.required }}
                  control={control}
                  errors={errors}
                  placeholder="Enter Quantity in No.s"
                  label="No of units Required"
                  type="number"
                />
              </Box>
              <Box {...styles("fullInput")}>
                <Input
                  customStyles={{
                    label: defaultStyles.label,
                    wrapper: defaultStyles.selectWrapper,
                    input: {
                      borderColor: "#36B1A0;",
                      boxShadow: "unset",
                    },
                  }}
                  name={`subComponents[${index}].costRequired`}
                  rules={{ required: errorMessage.required }}
                  control={control}
                  errors={errors}
                  placeholder="Enter Quantity in No.s"
                  label="Cost Required(Rs.)"
                  type="number"
                />
              </Box>
            </Box>
          </Box>
        ))}
        {/* Button to add new sub-component */}
        <Button
          text="Add Sub Component"
          onClick={() =>
            append({
              activityId: "",
              perticularOfItem: "",
              unit: "",
              costPerUnit: "",
              numberOfUnits: "",
              costRequired: "",
            })
          }
          customStyles={{
            root: { marginTop: "20px", width: "fit-content" },
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          width: "100%",
          marginTop: "30px",
          marginBottom: "20px",
        }}
      >
        <Button
          text="Save and Draft"
          type="submit"
          disabled={!isValid}
          customStyles={{
            root: {
              fontSize: "16px",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default EcoRestorationProgress;
